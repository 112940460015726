@use "vendors";

// ----------------------------------------overwrite bootstrap----------------------------------------

dd,
dl,
dt,
label,
p,
table,
ul {
    margin-bottom: 0px !important; // needs the important for override
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // just go with mb-1
    margin-bottom: 4px !important; // needs the important for override
}

hr {
    // just go with my-2
    margin-top: 8px !important; // needs the important for override
    margin-bottom: 8px !important; // needs the important for override
}

caption {
    // bootstrapp-4 moves table caption to bottom, we want it at the top. therefore important needed
    caption-side: top !important;

    // just go with py-2
    padding-top: 8px !important; // needs the important for override
    padding-bottom: 8px !important; // needs the important for override
}

// general error response for inputs, keep the important, too risky to remove
.is-invalid,
.is-invalid-warning {
    &.is-invalid {
        border-color: vendors.$danger !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF4136' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF4136' stroke='none'/%3e%3c/svg%3e") !important;

        &:focus,
        &.focus,
        &:active,
        &.active:focus {
            outline: none !important;
            box-shadow: vendors.$danger-box-shadow !important;
        }
    }

    &.is-invalid-warning {
        border-color: vendors.$warning !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF851B' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF851B' stroke='none'/%3e%3c/svg%3e") !important;

        &:focus,
        &.focus,
        &:active,
        &.active:focus {
            outline: none !important;
            box-shadow: vendors.$warning-box-shadow !important;
        }
    }

    background-repeat: no-repeat !important;
    background-position-x: right !important;
    background-position-y: center !important;
    background-size: vendors.$valid-image-size vendors.$valid-image-size !important;
    padding-right: calc(
        4px + #{vendors.$valid-image-size}
    ) !important; // some pr-1 for distance of number and image
}

// fixing wrong position of validation icon after build. only for text/number-inputs, keep the important, too risky to remove
.was-validated .form-control:invalid,
.form-control.is-invalid,
.form-control.is-invalid-warning,
.was-validated .form-control:valid,
.form-control.is-valid {
    background-position: right vendors.$valid-image-size-half center !important;
    padding-right: calc(
        4px + #{vendors.$valid-image-size} + #{vendors.$valid-image-size-half}
    ) !important; // first 2 just like top paddings, last is extra padding for button distance

    &.inline-select-dropdown {
        // we add 12px for dropdown-arrow in both below: sqrt(8.5^2+8.5^2)
        background-position: right
            calc(#{vendors.$valid-image-size-half} + 12px) center !important;
        padding-right: calc(
            4px + #{vendors.$valid-image-size} + #{vendors.$valid-image-size-half} +
                12px
        ) !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$danger-box-shadow !important;
    }
}

// validation of form for checkbox, keep the important, too risky to remove
.was-validated input[type="checkbox"]:invalid {
    &::before {
        content: "" !important;
        display: inline-block !important;
        width: 13px !important;
        height: 13px !important;
        border: 2px solid vendors.$danger-border !important;
        border-radius: 0.25rem !important;
        vertical-align: top !important; // top of the checkbox
    }
}

// adding colored border to navbar
.navbar {
    // make top/bottom smaller, so save screen-space, if changed, check with NAVBAR_HEIGHT
    padding-top: 2px !important; // keep the important to override our own card-padding
    padding-bottom: 2px !important; // keep the important to override our own card-padding

    border-width: 0 0 4px 0;
    border-color: vendors.$primary;
    // height: 50px !important; // looks bad, if screen is small
}

// remove odd padding
.navbar-brand {
    padding: 0px;
}

// smaller dropdown padding
.dropdown-item {
    padding-top: 0px;
    padding-left: 8px; // mx-2
    padding-right: 8px; // mx-2
    padding-bottom: 0px;
}

// changing primary button colors on hover
// adding primary color scheme to bisabled buttons, keep important for override
.btn-primary {
    &:disabled {
        border-color: vendors.$primary-border !important;
    }

    &:hover {
        background-color: vendors.$primary-hover !important;
        border-color: vendors.$primary-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$primary-box-shadow !important;
    }
}

// bootswatch for some reason does not provide this, keep important for override
.btn-secondary {
    &:disabled {
        border-color: vendors.$secondary-border !important;
    }

    &:hover {
        border-color: vendors.$secondary-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$secondary-box-shadow !important;
    }
}

// bootswatch for some reason does not provide this, keep important for override
.btn-info {
    &:disabled {
        border-color: vendors.$info-border !important;
    }

    &:hover {
        border-color: vendors.$info-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$info-box-shadow !important;
    }
}

// just border darker, keep important for override
.btn-warning {
    &:not(:hover) {
        border-color: vendors.$warning-border !important;
    }

    &:hover {
        border-color: vendors.$warning-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$warning-box-shadow !important;
    }
}

// just border darker, keep important for override
.btn-danger {
    &:not(:hover) {
        border-color: vendors.$danger-border !important;
    }

    &:hover {
        border-color: vendors.$danger-border-hover !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active:focus {
        outline: none !important;
        box-shadow: vendors.$danger-box-shadow !important;
    }
}

.btn-link {
    text-transform: none; // override uppercase
}

.alert {
    // just go with mb-2 and override bootstrap mb-3
    margin-bottom: 8px;
}

// no "!important", pb-0 would not work
.alert,
.card-header,
.modal-header,
.toast-header,
.card-body,
.modal-body,
.toast-body,
.card-footer,
.modal-footer,
.toast-footer,
.navbar,
.list-group-item {
    padding: vendors.$card-spacer-y;
}

// only left&right margin
.container-fluid {
    padding-left: vendors.$card-spacer-y;
    padding-right: vendors.$card-spacer-y;
}

.modal-footer {
    > * {
        margin: 0px; // no "!important", as it will overwrite bootstrap
    }
}

// uses white text on card-headers
.card-header.bg-primary,
.card-header.bg-success,
.card-header.bg-danger,
.card-header.bg-warning,
.card-header.bg-info,
.card-header.bg-primary > a:not(.btn) {
    color: vendors.$white;
}

// overwriting bg primary, keep important for override
.bg-primary {
    background-color: vendors.$primary !important;
    border-color: vendors.$primary-border !important;
}

// nav-links ugly bottom border stuff
.nav-link {
    border-bottom-color: transparent !important; // "!important" needed for bottom-border for tabs

    > span.nav-span {
        display: block;
        height: 22px;
    }
}

// overwriting table defaults
.table .thead-dark th {
    background-color: vendors.$primary;
    border-color: vendors.$primary;
    color: vendors.$white;

    // make table header links (used for sorting) visible when using dark table headers
    a {
        color: vendors.$white;
    }
}

// overwrite to match our navbar perfectly
.sticky-top {
    top: 40px; // matched 100% with NAVBAR_HEIGHT
    position: sticky;
    z-index: 999; // overwrite set z-index
}

.fixed-bottom {
    z-index: auto; // overwrite set z-index
}

// ----------------------------------------custom styles----------------------------------------

// just a primary-color class
.primary-color {
    background-color: vendors.$primary;
}

// just a primary-color text
.primary-color-text {
    color: vendors.$primary;
}

.pointer-cursor {
    cursor: pointer;
}

.grab-cursor {
    cursor: grab;
}

.can-go-fullscreen {
    &:not(:fullscreen) {
        #fullscreen-only {
            display: none;
        }
    }

    &:fullscreen {
        #fullscreen-never {
            display: none;
        }
    }
}

// overwriting checkbox defaults
.checkbox-primary {
    accent-color: vendors.$primary;
}

// overwriting badge defaults
.badge-primary {
    background-color: vendors.$primary;
}

// tab-height
.tab {
    height: 44px; // if this changes, loot at _variables.ts TAB_HEIGHT
}

.bg-focus {
    background-color: beige;
}

.bg-tertiary {
    background-color: vendors.$secondary-hover;
    border-color: vendors.$secondary-border-hover;
}

// white-space is so tricky, do not remove important
.space-nowrap {
    white-space: nowrap !important;
}

// white-space is so tricky, do not remove important
.text-dot-overflow {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;

    // add-on for text-dot-overflow, if there is more text around the link
    &.span-outside-link {
        vertical-align: bottom;
        display: inline-block;
    }
}

// table cells with overflow need some arbitrary width
.big-width-cell {
    width: 90%; // high number to align everything right
    max-width: 0px; // we have to set a max-width, but width will overwrite it
}

.div-overflow-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.div-overflow-y {
    overflow-x: hidden;
    overflow-y: auto;
}

// add has-button class to align buttons
.has-buttons {
    display: flex;

    & > *:first-child {
        flex-grow: 1;
        align-self: center;

        // just like text-dot-overflow in customization.ts
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }

    .btn,
    .btn-group {
        white-space: nowrap !important;
    }

    .card-height {
        padding-top: 27px; // 8px padding, 4px border, 15px actual button
    }
}

// expands the link to take full space of the card footer to make it easier to click
.card-footer.single-href {
    padding: 0;
    height: auto;

    a {
        width: 100%;
        display: block;
        padding: vendors.$card-spacer-y;
    }
}

// center align columns except the first column
table.table-center {
    th:not(:first-child),
    td:not(:first-child) {
        text-align: center;
    }
}

// right align columns except the first column
table.table-right {
    th:not(:first-child),
    td:not(:first-child) {
        text-align: right;
    }
}

// center align columns, just like "table-right" but no "!important"
table.table-right-sticky {
    th:not(:first-child),
    td:not(:first-child) {
        text-align: right; // do not add "!important" here !!!!!!!!!!!
    }
}

// increase the clickable area for the checkbox
.table-checkbox-padding {
    padding: 0 !important; // need important, as it is an override of table-sm

    label {
        margin-bottom: 0;
        vertical-align: -webkit-baseline-middle; // middle of the text

        input {
            margin: vendors.$table-cell-padding-sm; // checkbox cannot have padding for some reason
        }
    }

    // overwrite label position in table header
    label#th-checkbox {
        vertical-align: text-bottom; // middle of the text
    }
}

.table-double-striped {
    tr#even {
        // sadly, you have to add id to every tr, otherwise not possible
        background-color: vendors.$table-accent-bg;
    }
}

.info-dl {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;

    dt {
        text-align: right;
        max-width: 40%;
        flex-basis: 40%;
        padding-left: map-get(vendors.$spacers, 2);
        padding-right: map-get(vendors.$spacers, 2);
    }

    dd {
        max-width: 60%;
        flex-basis: 60%;
        padding-left: map-get(vendors.$spacers, 2);
        padding-right: map-get(vendors.$spacers, 2);
    }
}

// style simple number inputs
.simple-number {
    width: 100%;
    text-align: center;
    border: 0;
    border-bottom: vendors.$border-width solid vendors.$border-color;
    background-color: transparent;

    // to mimik form-control focus-highlight
    &:focus {
        border-radius: 0.25rem;
        box-shadow: vendors.$primary-box-shadow;
        outline: none;
    }

    &.is-invalid {
        color: vendors.$danger;
        border-bottom-color: vendors.$danger;
    }
}

// style for all the cycles in svg
.element-transparent {
    opacity: 0.2;

    &:hover {
        opacity: 1;
    }
}

// style for canvas-only images
.canvas-chart {
    position: absolute;

    width: 100%;
    height: 100%;
}

.chart-invisible {
    position: absolute !important; // needed for svg-only charts to not collapse with !important
    visibility: hidden;
    margin: -100000px;
}
