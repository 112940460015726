// disable Bootswatch fonts loading and use packaged, offline font files instead
$web-font-path: "data:text/css;base64,";

// source sans pro
// $FontPathSourceSansPro: "~npm-font-source-sans-pro/fonts";

// fixes for bootswatch
$dropdown-link-color: #000000bf;

// specific for this
$chart-height: 25em;
$animation-duration: 0.5s;

// for LUM specific color scheme
$primary: #139839; // 34%, lumgreen, do not touch !!!
$primary-border: hsl(137, 78%, 29%); // 29%
$primary-hover: hsl(137, 78%, 31%); // 30%
$primary-border-hover: hsl(137, 79%, 22%); // 22%
$primary-box-shadow: 0 0 0 0.2rem #13983980; // copy from main color

// data from bootswatch & bootstrap
$secondary: #f0f0f0; // 94%
$secondary-border: hsl(0, 0%, 89%); // 89%
$secondary-hover: hsl(0, 0%, 87%); // 87%
$secondary-border-hover: hsl(0, 0%, 85%); // 85%
$secondary-box-shadow: 0 0 0 0.2rem #13983980; // gray is too invisible, take primary

// data from bootswatch & bootstrap
$info: #75caeb; // 69%
$info-border: hsl(197, 75%, 64%); // 64%
$info-hover: hsl(197, 75%, 62%); // 62%
$info-border-hover: hsl(197, 75%, 59%); // 59%
$info-box-shadow: 0 0 0 0.2rem #75caeb80; // copy from main color

// data from bootswatch & bootstrap
$warning: #ff851a; // 55%
$warning-border: hsl(28, 100%, 45%); // 50% normaly, need more contrast
$warning-hover: hsl(28, 100%, 48%); // 48%
$warning-border-hover: hsl(28, 100%, 40%); // 45% normaly, need more contrast
$warning-box-shadow: 0 0 0 0.2rem #ff851a80; // copy from main color

// data from bootswatch & bootstrap
$danger: #ff4238; //61%
$danger-border: hsl(3, 100%, 51%); // 56% normaly, need more contrast
$danger-hover: hsl(3, 100%, 54%); // 54%
$danger-border-hover: hsl(3, 100%, 46%); // 51% normaly, need more contrast
$danger-box-shadow: 0 0 0 0.2rem #ff423880; // copy from main color

// overwrite bootswatch & bootstrap
$link-hover-color: hsl(137, 78%, 25%); // 25%
$link-hover-decoration: none;
$input-focus-border-color: $primary;
$input-btn-focus-color: #13983980; // primary
$close-color: #000000;
$card-spacer-y: 6px; // if this changes, loot at _variables.ts CARD_PADDING

// extra variables add-ons, that will not overwrite anything
$valid-image-size: calc(0.75em + 0.375rem);
$valid-image-size-half: calc(#{$valid-image-size} / 2);

// bootstrap and bootswatch
@import "~bootswatch/dist/lumen/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
